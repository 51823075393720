import { FIELD_TYPES } from "constants/template-field-type";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Ada Chatbot",
    fields: {
        chatbotHeight: {
            type: FIELD_TYPES.TEXT,
            label: "Chatbot Height"
        },
        chatbotWidth: {
            type: FIELD_TYPES.TEXT,
            label: "Chatbot Width"
        }
    }
};
