function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import AssetConfig from "components/core/asset/fields-config";
import TestimonialConfig from "segments/desktop/core-components/testimonial/fields-config";
import FullParagraphConfig from "segments/desktop/paragraph/layouts/full-paragraph/full-paragraph-config";
import ThemeConfig from "segments/core-configs/theme-config";
const _FullParagraphConfig_fields = FullParagraphConfig.fields, { topic, mobileTextAlign, theme, textAlign, buttonConfig, titleColor, bodyColor } = _FullParagraphConfig_fields, restOfParagraphConfig = _object_without_properties(_FullParagraphConfig_fields, [
    "topic",
    "mobileTextAlign",
    "theme",
    "textAlign",
    "buttonConfig",
    "titleColor",
    "bodyColor"
]);
const _TestimonialConfig_fields = TestimonialConfig.fields, { backgroundColor } = _TestimonialConfig_fields, restOfTestimonialConfig = _object_without_properties(_TestimonialConfig_fields, [
    "backgroundColor"
]);
export default {
    type: FIELD_TYPES.NESTED,
    label: "Carousel Tabs",
    fields: _object_spread_props(_object_spread({}, ThemeConfig), {
        tabsTitle: {
            type: FIELD_TYPES.TEXT,
            label: "Tabs Title",
            translatable: true
        },
        tabs: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Tabs",
            maxLimit: 8,
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: {
                    tabContent: {
                        type: FIELD_TYPES.NESTED,
                        label: "Tab content",
                        fields: {
                            tabTitleImage: {
                                type: FIELD_TYPES.GALLERY,
                                label: "Tab title image",
                                translatable: true
                            },
                            asset: {
                                type: FIELD_TYPES.NESTED,
                                label: "Background asset",
                                fields: AssetConfig
                            },
                            testimonial: {
                                type: FIELD_TYPES.NESTED,
                                label: "Testimonial",
                                fields: _object_spread_props(_object_spread({}, restOfTestimonialConfig), {
                                    button: {
                                        type: FIELD_TYPES.NESTED,
                                        label: "Button",
                                        fields: {
                                            buttonText: {
                                                type: FIELD_TYPES.TEXT,
                                                label: "Button text"
                                            },
                                            buttonUrl: {
                                                type: FIELD_TYPES.TEXT,
                                                label: "Button url"
                                            }
                                        }
                                    }
                                })
                            },
                            stats: {
                                type: FIELD_TYPES.MULTIPLE_NESTED,
                                label: "Stats",
                                maxLimit: 4,
                                nestedConfig: {
                                    type: FIELD_TYPES.NESTED,
                                    fields: restOfParagraphConfig
                                }
                            },
                            cardImageHeight: {
                                type: FIELD_TYPES.TEXT,
                                label: "Card image height"
                            }
                        }
                    }
                }
            }
        },
        autoplay: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Autoplay"
        },
        autoplaySpeed: {
            type: FIELD_TYPES.TEXT,
            label: "Autoplay Speed"
        },
        inCardVersion: {
            type: FIELD_TYPES.BOOLEAN,
            label: "In card version - in test, don't use"
        }
    })
};
