function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import AssetContentCardConfig from "./components/asset-content-card/asset-content-card-config";
import { GRID_ITEM_TYPES } from "./grid-item-types";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Dynamic Grid",
    fields: {
        theme: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Theme"
        },
        gridPadding: {
            type: FIELD_TYPES.TEXT,
            label: "Grid Padding"
        },
        horizontalGap: {
            type: FIELD_TYPES.TEXT,
            label: "Horizontal Gap"
        },
        verticalGap: {
            type: FIELD_TYPES.TEXT,
            label: "Vertical Gap"
        },
        backgroundColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Background Color"
        },
        borderRadius: {
            type: FIELD_TYPES.TEXT,
            label: "Border Radius"
        },
        itemMinWidth: {
            type: FIELD_TYPES.TEXT,
            label: "Item Minimum Width",
            tooltip: "Override the default minimum width for all items"
        },
        gridItems: {
            type: FIELD_TYPES.CONDITIONAL_NESTED,
            label: "Grid items",
            conditionalKeyName: "type",
            options: [
                {
                    text: GRID_ITEM_TYPES.ASSET_CONTENT_CARD,
                    type: FIELD_TYPES.NESTED,
                    fieldsConfig: {
                        cards: {
                            type: FIELD_TYPES.MULTIPLE_NESTED,
                            label: "Asset Content Cards",
                            nestedConfig: _object_spread({}, AssetContentCardConfig)
                        }
                    }
                }
            ]
        }
    }
};
