function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import AssetFieldsConfig from "components/core/asset/fields-config";
import { XS, SM, MD, LG } from "constants/sizes";
import PillConfig from "components/core/pill/fields-config";
import buttonConfig from "segments/desktop/core-components/button/fields-config";
const enhancedButtonConfig = _object_spread_props(_object_spread({}, buttonConfig), {
    options: buttonConfig.options.map((option)=>{
        if (option.text === "Signup") {
            return _object_spread_props(_object_spread({}, option), {
                fieldsConfig: _object_spread_props(_object_spread({}, option.fieldsConfig), {
                    buttonSize: {
                        type: FIELD_TYPES.COMBO,
                        label: "Button Size",
                        options: [
                            {
                                value: XS,
                                text: "Small"
                            },
                            {
                                value: SM,
                                text: "Medium"
                            },
                            {
                                value: LG,
                                text: "Large"
                            }
                        ]
                    }
                })
            });
        }
        return option;
    })
});
export default {
    type: FIELD_TYPES.NESTED,
    label: "Asset Content Card",
    fields: {
        asset: {
            type: FIELD_TYPES.NESTED,
            label: "Asset",
            fields: AssetFieldsConfig
        },
        title: {
            type: FIELD_TYPES.TEXT,
            label: "Title Text",
            translatable: true
        },
        titleFontSize: {
            type: FIELD_TYPES.COMBO,
            label: "Title Size",
            options: [
                {
                    value: XS,
                    text: "22px"
                },
                {
                    value: SM,
                    text: "28px (formerly 32px)"
                },
                {
                    value: MD,
                    text: "36px (formerly 40px)"
                },
                {
                    value: LG,
                    text: "44px (formerly 50px)"
                }
            ]
        },
        titleFontWeight: {
            type: FIELD_TYPES.COMBO,
            label: "Title Font Weight",
            options: [
                {
                    value: 400,
                    text: "Normal (400)"
                },
                {
                    value: 500,
                    text: "Medium (500)"
                },
                {
                    value: 600,
                    text: "Semibold (600)"
                },
                {
                    value: 700,
                    text: "Bold (700)"
                }
            ]
        },
        minWidth: {
            type: FIELD_TYPES.TEXT,
            label: "Minimum Width",
            description: "Enter minimum width (e.g., '200px' or '20rem')"
        },
        maxWidth: {
            type: FIELD_TYPES.TEXT,
            label: "Maximum Width",
            description: "Enter maximum width (e.g., '800px' or '80rem')"
        },
        borderRadius: {
            type: FIELD_TYPES.TEXT,
            label: "Border Radius"
        },
        borderThickness: {
            type: FIELD_TYPES.TEXT,
            label: "Border Thickness"
        },
        borderColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Border Color"
        },
        cardPadding: {
            type: FIELD_TYPES.TEXT,
            label: "Card Padding"
        },
        contentPadding: {
            type: FIELD_TYPES.TEXT,
            label: "Content Padding"
        },
        assetBottomMargin: {
            type: FIELD_TYPES.TEXT,
            label: "Asset Bottom Margin"
        },
        titleBottomMargin: {
            type: FIELD_TYPES.TEXT,
            label: "Title Bottom Margin"
        },
        contentType: {
            type: FIELD_TYPES.CONDITIONAL_NESTED,
            label: "Content Type",
            conditionalKeyName: "type",
            options: [
                {
                    text: "Pills",
                    type: FIELD_TYPES.NESTED,
                    fieldsConfig: {
                        pills: {
                            type: FIELD_TYPES.MULTIPLE_NESTED,
                            label: "Pills",
                            nestedConfig: _object_spread({}, PillConfig)
                        }
                    }
                }
            ]
        },
        buttonPadding: {
            type: FIELD_TYPES.TEXT,
            label: "Button Padding"
        },
        dividerConfig: {
            type: FIELD_TYPES.NESTED,
            label: "Divider Config",
            fields: {
                backgroundColor: {
                    type: FIELD_TYPES.COLOR_PICKER,
                    label: "Divider Color"
                },
                borderThickness: {
                    type: FIELD_TYPES.TEXT,
                    label: "Divider Thickness"
                }
            }
        },
        buttonConfig: enhancedButtonConfig
    }
};
