// plop_marker:layouts_import
import CarouselSelectableProductCardsGrid from "./carousel-selectable-product-cards-grid/index.js";
import BackgroundImageCardsGrid from "./background-image-cards-grid/index.js";
import AnimatedIconsGrid from "./animated-icons-grid/index.js";
import MarqueeCardsGrid from "./marquee-cards-grid/index.js";
import HoverableCardsGrid from "./hoverable-cards-grid/index.js";
import MarqueeGrid from "./marquee-grid/index.js";
import BadgesGrid from "./badges-grid/index.js";
import SlidingCardGrid from "./sliding-card-grid/index.js";
import PressGrid from "./press-grid/index.js";
import ConnectedProductsGrid from "./connected-products-grid/index.js";
import FlexibleCardsGrid from "./flexible-cards-grid/index.js";
import SquareTagsGrid from "./square-tags-grid/index.js";
import ProductCardsGrid from "./product-cards-grid/index.js";
import MasonryGrid from "./masonry-grid/index.js";
import TagItemsGrid from "./tag-items-grid/index.js";
import IconWithParagraphGrid from "./icon-with-paragraph-grid/index.js";
import SelectableTagsWithCarouselGrid from "./selectable-tags-with-carousel-grid/index.js";
import BlogCardsGrid from "./blog-cards-grid/index.js";
import ButtonImageTagsGrid from "./button-image-tags-grid/index.js";
import ButtonTagsGrid from "./button-tags-grid/index.js";
import CardsGrid from "./cards-grid/index.js";
import TagsGrid from "./tags-grid/index.js";
import SelectableTagsGrid from "./selectable-tags-grid/index.js";
import ImageWithParagraphGrid from "./image-with-paragraph-grid/index.js";
import OnlyImageGrid from "./only-image-grid/index.js";
import DynamicGridComponent from "./dynamic-grid/index.js";
export default {
    // plop_marker:layouts_add_layout
    CarouselSelectableProductCardsGrid,
    BackgroundImageCardsGrid,
    AnimatedIconsGrid,
    MarqueeCardsGrid,
    HoverableCardsGrid,
    MarqueeGrid,
    BadgesGrid,
    SlidingCardGrid,
    PressGrid,
    ConnectedProductsGrid,
    FlexibleCardsGrid,
    SquareTagsGrid,
    ProductCardsGrid,
    MasonryGrid,
    TagItemsGrid,
    IconWithParagraphGrid,
    SelectableTagsWithCarouselGrid,
    BlogCardsGrid,
    ButtonImageTagsGrid,
    ButtonTagsGrid,
    CardsGrid,
    TagsGrid,
    SelectableTagsGrid,
    ImageWithParagraphGrid,
    OnlyImageGrid,
    DynamicGridComponent
};
