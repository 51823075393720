import { FIELD_TYPES } from "constants/template-field-type";
import { MD, MS, SM } from "/constants/sizes";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Pill Component",
    fields: {
        label: {
            type: FIELD_TYPES.TEXT,
            label: "Pill Text"
        },
        backgroundColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Background Color"
        },
        borderColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Border Color"
        },
        borderThickness: {
            type: FIELD_TYPES.TEXT,
            label: "Border Thickness"
        },
        borderRadius: {
            type: FIELD_TYPES.TEXT,
            label: "Border Radius"
        },
        textColor: {
            type: FIELD_TYPES.COLOR_PICKER,
            label: "Text Color"
        },
        fontSize: {
            type: FIELD_TYPES.TEXT,
            label: "Font Size",
            options: [
                {
                    value: SM,
                    text: "14px"
                },
                {
                    value: MS,
                    text: "16px"
                },
                {
                    value: MD,
                    text: "18px"
                }
            ]
        },
        padding: {
            type: FIELD_TYPES.TEXT,
            label: "Text Padding"
        },
        margin: {
            type: FIELD_TYPES.TEXT,
            label: "Pill Margin"
        },
        hoverOpacity: {
            type: FIELD_TYPES.TEXT,
            label: "Hover Opacity"
        }
    }
};
